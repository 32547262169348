import React from 'react';
import Drawer from '@mui/material/Drawer';
import styled from 'styled-components';
import { StandardSection } from '../../layout/sections/StandardSection'
import { SwipeableDrawer } from '@mui/material';
import { StyledTitle } from '../../components/ui/typography/StyledTitle';
import { Divider } from '../../components/ui/Divider';
import {AiFillCloseSquare} from "react-icons/ai";
import {useDrawer} from "../../js/hooks/context/DrawerContext.jsx";
import {BiLeftArrowAlt} from "react-icons/bi";

const BaseDrawer = styled(SwipeableDrawer)`
  .MuiDrawer-paper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-height: 100px;
    height: fit-content;
    max-height: 90svh;
    /* padding: 20px; */
    //padding-top: 30px;
    box-sizing: border-box;

    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    background-color: var(--default-primary-colour);
    z-index: 999999999;


    /* Hide scrollbar for WebKit browsers */
    &::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for Firefox */
    scrollbar-width: none;

    /* Hide scrollbar for Internet Explorer and Edge */
    -ms-overflow-style: none;
    
    
    @media screen and (min-width: 1024px){
      justify-content: center;
      width: 40vw;
      max-width: 40vw;
      height: 100dvh;
      max-height: 100dvh;
      border-radius: 0px;
    }

    @media screen and (min-width: 1920px){

    }
  }
`

const StyledDrawer = styled(BaseDrawer)`
  outline: 2px solid yellow;
`;

const HorizontalDrawer = styled(BaseDrawer)`
  .MuiDrawer-paper {
    justify-content: center;
    align-items: center;
    width: 100vw;
    min-height: 100dvh;
    padding-top: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    background-color: var(--primary-colour); //rgba(0,0,0,0.7);
    backdrop-filter: blur(3px);
  }

`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 50px;
  height: 50px;
  gap: 10px;
  padding: 20px 20px;
  background-color: rgba(50,50,50,0.2);
  border-bottom: solid 1px rgba(200,200,200,0.1);
  position: sticky;
  top: 0;
  z-index: 1000;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;


  /* Hide scrollbar for WebKit browsers */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for Firefox */
  scrollbar-width: none;

  /* Hide scrollbar for Internet Explorer and Edge */
  -ms-overflow-style: none;

  @media screen and (min-width: 768px) {
    min-width: 100%;
    width: 100%;
    overflow-x: hidden;
  }
`

const DrawerPanel = ({ isOpen, toggleDrawer, children, anchor, style, title }) => {

  const { closeDrawer, openPreviousDrawer, previousDrawer } = useDrawer();

  const drawerMap = {
    top: StyledDrawer,
    bottom: StyledDrawer,
    left: HorizontalDrawer,
    right: HorizontalDrawer
  }

  const Drawer = drawerMap[anchor]

  return (
    <Drawer
      name={'drawer-panel'} 
      swipeAreaWidth={56}
      anchor={innerWidth > 768 ? 'right' : anchor}
      open={isOpen}
      onOpen={() => console.log('epened')}
      onClose={toggleDrawer}
      style={style}
      >


        { anchor !== 'right' || anchor !== 'left' &&
          <Header>
            <section>
              { previousDrawer && <BiLeftArrowAlt onClick={() => openPreviousDrawer()} color={'var(--colour-light)'} /> }
            </section>
            <StyledTitle title={title} size={'1rem'}  />
            <section>

            </section>
          </Header>
        }

        <Content id='panel-content' >
        {children}
        </Content>


      {anchor === 'right'  &&
          <div style={{paddingBottom: '20px'}}>
            <AiFillCloseSquare size={30} color={'var(--accent-colour)'} onClick={() => closeDrawer()} />

          </div>
      }
    </Drawer>
  );
};

export default DrawerPanel;
