
// Wrapper for two-column layout on larger screens
import React from "react";
import styled from "styled-components";

const MemberCardContainer = styled.div`
    display: grid;
    place-items: center;
    padding-top: 20px;
    //padding: 40px 0px;
    width: 350px;
    margin-top: -20px;
    z-index: 1;
        // background-image: ${props => `url(${props.image})`};
    background-size: contain;
    background-position: center;
    overflow: hidden; // To hide overflow of child elements

    // Pseudo-element for gradient overlay
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //background: linear-gradient(to top, var(--primary-colour), transparent);
        z-index: 0; // Ensure the overlay is above the image
        pointer-events: none; // Allows interaction with underlying elements
    }
`;
export const CardBase = styled.div`
    width: 100%;
    max-height: 200px;
    max-width: 350px;
    // background-image: ${(props) => props.image};
    background: var(--primary-colour);
    background-image: ${props => `url(${props.image})`};
    
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: var(--border-radius-default);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    //border: 1px solid rgba(255, 255, 255, 0.09);
    margin: 0 10px;
    z-index: 4;
    transition: opacity 0.3s ease;

    @media (min-width: 1024px) {
        //background-image: none; /* Remove background image */
    }
`;


// Adjusted width and height for smaller tier cards


export const CardText = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
`;

export const CardHeader = styled.div`
    width: 100%;
    height: 180px; // Adjust height to desired size for the header
    background-size: cover;
    background-position: center;
    position: relative; // Allow for layering of gradient
    color: var(--text-colour);

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //background: linear-gradient(to bottom, transparent, var(--default-primary-colour));
        pointer-events: none; // Allow interaction with elements beneath
    }
`;



export const CardFooter = styled(CardText)`
    font-size: 14px;
    align-items: flex-end;
    flex-direction: column;
    line-height: 1.2; /* Shortened line height */
`;


export const MembersCardComponent = ({ cardName, cardType, tierName, userName, points, image }) => (
    <MemberCardContainer
        id={'member-card'}
        image={image}
    >
        <CardBase image={image}>
            <CardHeader>
                <div>{cardName}</div>
                <div>{tierName}</div>
            </CardHeader>
            <CardFooter>
                <div>{userName}</div>
                <div><strong>{points} Points</strong></div>
            </CardFooter>
        </CardBase>
    </MemberCardContainer>
);